// default light theme


@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;800&display=swap');
html,
body {
  background-color: $body-bg;
  font-family: 'Nunito Sans', sans-serif;
}

