$theme_option_item_height: 60px;

// Colors options
// for logo (there're 2, one for sidenav-header, one for header logo, here only for sidenav-header), top header, sidenav
.color-option-check {
  position: relative;
  display: block;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  .checkedblue .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner{
    border-radius: 36px !important;
    border: 2px solid #2a58ae !important;
    }
    .posbgecolor video{
      width: 556px !important;
      height: 314px;
  }
  .posbgecolor iframe{
    width: 556px !important;
    height: 314px;
}
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
      text-align: center;
      line-height: 30px;  
      color: #fff;  
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //  
  // .ant-select-dropdown {
  //   z-index: 554;
  //   }
  .color-option-item {
    overflow: hidden;
    display: block;
    box-shadow: 0 0 2px rgba(0,0,0,.1);
    margin-bottom: 15px;

    > span {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
    }

    .item-header {
      height: 10px;
    }
  }
}

// to make it more distinguishable from white color
.color-option-check {
  .bg-page {
    background-color: #ececec !important; // vs #fff, #f1f1f1
  }
}



// Themes options
.theme-options {
  > div {
    padding: 0;
  }
}
.theme-option-check {
  position: relative;
  display: block;
  margin: 0;
  font-weight: normal;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0,0,0,.2);
      text-align: center;
      line-height: $theme_option_item_height;  
      color: #fff;
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //  
  .theme-option-item {
    overflow: hidden;
    display: block;

    > span {
      display: block;
      text-align: center;
      height: $theme_option_item_height;
      line-height: $theme_option_item_height;
      text-transform: uppercase;
    }
  }
}

.steps .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  border-bottom: solid thin rgba(0, 0, 0, 0.1);
  padding-bottom: 23px;
}
.steps .steps-content .col-md-12.d-flex.text-left {
  padding: 0px;
}
.steps .row .school-main{
  width: 100%;
}
.steps .ant-form-item label {
  display: flex;
}
::selection {
  color: #0b0b0b;
  background: #2a58ae1a;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #dae7fe;
}
table.table.staff.table-bordered.w-100 tr th:first-child, table.table.staff.table-bordered.w-100 tr td:first-child {
  width: 50px;
}
table.table.semestar.table-bordered.w-100 {
  width: 1500px;
}


.waiting .ant-card-head {
  background-color: #2a58ae;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title{
  padding: 0px 0 !important;
}
.ant-card-small > .ant-card-head{
  min-height: initial !important;
}
.waiting .ant-card-type-inner .ant-card-head-title{
  color:white
}

.sentforedits .ant-card-head {
  background-color: #ffc53d;
}

.sentforedits .ant-card-type-inner .ant-card-head-title{
  color:white
}

.approved .ant-card-head {
  background-color: #66bb6a;
}

.approved .ant-card-type-inner .ant-card-head-title{
  color:white
}

.published .ant-card-head {
  background-color: #2A58AE;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
 border-color: #2A58AE;

}
.brdradnone .ant-card-head .ant-card-head-wrapper .ant-card-head-title{
  padding: 3px 0px !important;
}
.brdradnone .ant-card-head{
  min-height: initial !important;
}
.published .ant-card-type-inner .ant-card-head-title{
  color:white
}
.ant-pagination-next{
  margin-right: 10px !important;
}
.ant-radio-inner::after{
  background-color: #2a58ae !important;
  border-color:  #2a58ae !important;
}
.ant-radio-checked .ant-radio-inner{

  border-color:  #2a58ae !important;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  border-radius: 0px;
  background: #fff;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dae7fe; 
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a58ae; 
}



span.ant-select-arrow {
  color: #2a58ae;
}

i.anticon.anticon-delete.delete {
  right: 5px;
  text-align: right;
  bottom: 5px;
  top: initial;
  opacity: 0.5;
}
.posrtop65{
  position: relative;
  bottom: 38px;
}
.heightficer{
  height: auto !important;
  padding-right: 190px;
  position: relative;
  display: inline-block;
  padding-top: 25px;
}

i.anticon.anticon-delete.delete:hover{
  opacity:1;
}
.artfiletext {
  font-size: 15px !important;
  font-weight: 600;
  line-height: 19px;
  padding-top: 5px;
  color: #2A58AE;
}
.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom:5px;
  // background: #f8f8f8;
}
.rotateicon {    transform: rotate(
  90deg
  );
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -webkit-transform: rotate(
  90deg
  );
      width: 1px;
      position: relative;
      bottom: 11px;
      right: 3px;
      font-size: 22px;
      font-weight: bold;
      line-height: 32px;
  }
  .tox-tinymce:hover{
    box-shadow: 0 0 0 2px #ffffff33 !important;
  }
.butshowmenu20 {
  border-radius: 36px !important;
  background-color: #c7c7c7 !important;
  color: #000 !important;
  height: 35px;
    width: 35px;
  transform: rotate(90deg);
}
.butshowmenu20:hover {
  border-radius: 36px !important;
  border: 1px solid #769de6 !important;
  background-color: #769de6 !important;
  color: #fff !important;
}
.demo-nav a {
  line-height: 30px;
  padding: 0 8px 0px 0px;
}
.ant-breadcrumb a:hover {
  color: #2a58ae !important;
}
.app-list {
  margin-top: 16px;
}
.demo {
  // margin: 16px;
  margin:0px;

}
.tox-notifications-container{
  display: none !important
}
.mce-notification {display: none !important;}
// .container-fluid {
//   padding-left: 30px;
//   padding-right: 30px;
//   height: 100vh !important;
//   overflow-y: scroll !important;
// }

.ant-table-thead > tr > th{
  font-weight: 600 !important;
  // font-size: 15px !important;
  background-color: #2a58ae  !important;
  color: #FFF !important;
  // padding: 11px 13px !important;
}
// .ant-table-tbody > tr > td {
//   vertical-align: top !important;
// }
.ant-table-wrapper{
  border-radius: 15px 15px 0px 0px !important;
}
.ant-table-bordered .ant-table-thead > tr > th{
  border-right:none  !important;
}
.ant-table-bordered .ant-table-body > table{
  border: 1px solid #e8e8e8 !important;
}
.ant-table table, .ant-table-placeholder{
  border: 1px solid #e8e8e8 !important;
}
.ant-table-bordered .ant-table-tbody > tr > td{
  border-right: none  !important;
}
.ant-table-tbody > tr > td{
  border-right: none  !important;
}
.tableinner{
  min-height: 500px;
  height: auto;
  background: #FFF;
  border: 1px solid #ececec;
  padding: 5px;
}

.clearboth{
  clear: both;
}
.posrel12{
  position: relative;
right: 12px;
}
.draggdrop{
  position: relative;
  z-index: 5;
  left: 3px;
  text-align: center;
  bottom: 5px;
  height: 30px;
  width: 30px;
  background-color: #eaeaea;
  display: inline-block;
  border-radius: 36px;
}
.draggdrop i{
  font-size: 22px;
  /* font-weight: 800; */
  color: #464a58;
  top: 4px;
  text-align: center;
  position: relative;
}
.width50per{
  width: 50%;
}
.display-inlineBlock{
  display: inline-block !important;
}
.addquest {
  float: right;
}
.fullpitch i{
  background-color: #d8d8d8;
  padding: 3px;
  border-radius: 5px;
  position: relative;
  bottom: 6px;
  right: 5px;
}
.educatertable{
  min-height: 250px;
  height: auto;
  background: #FFF;
  border: 1px solid #ececec;
  padding: 5px;
}
.article-tite {
  color: #131D40 !important;
  font-size: 22px !important;
  line-height: 25px !important;
}
.staff thead tr th{
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #2a58ae !important;
  color: #FFF !important;
  padding: 2px 8px !important;
}
.semestar thead tr th{
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #2a58ae !important;
  color: #FFF !important;
  padding: 11px 6px !important;
}
.table.table.studmyt.table-bordered.w-100 tr th:first-child{
  width: 220px !important;
}
.ant-breadcrumb-separator {
  margin: 0 0px !important;
}
.studmyt thead tr th{
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #2a58ae !important;
  color: #FFF !important;
  padding: 11px 13px !important;
}
.schooladm thead tr th{
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #2a58ae !important;
  color: #FFF !important;
  padding: 11px 8px !important;
}
.tablewidth{
  width: 159px !important;
}
.ant-btn-link{
  // cursor: pointer !important;
  background-color: #FFF !important;
  color: #2a58ae !important;
}
.ant-btn-link:hover {
  background-color:  #FFF  !important;
  border:  none  !important;
  color: #2a58ae !important;
}
.ant-btn-secondary{
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-secondary:hover{
  color: #fff !important;
  background-color: #464a58 !important;
  border-color: #464a58 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-link span{
  // cursor: pointer !important;
  background-color: #FFF !important;
  color: #2a58ae !important;
}
.ant-btn-link span:hover {
  background-color:  #FFF  !important;
  border:  none  !important;
  color: #2a58ae !important;
}
.question-ontology{
  // width: 220px;
  width: 95% !important;

}
.gallery-2 {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  margin-top: 0px;
  overflow: hidden;
}
.anchoer{
  color: #005eb5;
  text-decoration: underline;
}
.setitablewidth .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
  width: 180px;
  text-align: right;
  padding-left:  0px !important;
  padding-right:  4px !important;
  }
 .setitablewidth .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
  width: 180px;
  padding-left:  0px !important;
  padding-right:  4px !important;
  text-align: right;
  }
  .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
    width: 20px;
    text-align: right;
    }
   .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
    width: 20px;
    text-align: right;
    }
  .ant-notification-topLeft{
    left: 35% !important;
  }
  .notification-main{
    cursor: pointer;
    background: #f7f7f7;
    overflow: hidden;
    line-height: 1.5;
    position: relative;
    left: 0% !important;
    top: 0px;
    z-index: 1000;
    display: block;
    bottom: auto;
  }
  .cuspointer{
    cursor: pointer;
  }
  .pstyletag p {
    margin-bottom: 6px !important;
    font-size: 15px !important;
    color: #282828 !important;
    line-height: 21px !important;
    text-align: left ;
  }
  .pstyletag, .pad0{
    overflow-y: auto !important;
  }
  .pstyletag table{
      width: 100%;
  }
  .coursetitle2 .pstyletag table {
    width: 100% !important;
  }
  .pstyletag table tbody tr td img{
    width: 100% !important;
  }
  .pstyletag table tbody tr td {
  min-width: 100px;
  padding: 8px;
  border: 1px solid #cacaca;
  }
  .cursorpointer{
    cursor: pointer;
  }
  .posrel {
    position: relative;
  }
  .pad0 table tbody tr td {
    min-width: 100px;
    border: 1px solid #cacaca;
    padding: 8px;
    }
    .pstyletag table tbody tr  {
     display: inherit;
      }
      .pad0 table tbody tr  {
        display: inherit;
        }
  .pad0 table tbody tr td img{
    width: 100% !important;
  }
  .pstyletag {
    font-size: 15px !important;
    color: #282828 !important;
    line-height: 21px !important;
    text-align: left ;
  }
  .pstyletag p span{
    margin-bottom: 6px !important;
    font-size: 15px !important;
    color: #282828 !important;
    line-height: 21px !important;
    text-align: left ;
  }
  .pstyletag p img {
    max-width: 500px;
  }
  .flashead > .hoverEffect:hover {
    border: 4px double #f3f3f3 !important;
    padding: 0px;
    transition: 0.5s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.5s;
  }
  .scrollflash div img {
    max-width: 600px;
    height: auto;
  }
  .minheight250 {
    min-height: 210px;
  }
  .overyarrow
  .flashcard-overlay-content
  .react-card-flip
  .react-card-flipper
  .react-card-front
  .testCard {
  height: 250px;
}
  .testCard {
    box-shadow: 0 0px 5px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
  }
  .flashead .react-card-flip .react-card-flipper .react-card-front .testCard {
    min-height: 150px;
    // max-height: 170px;
  }
  .minheight250 {
    min-height: 210px;
  }
  .flashead .testCard .ant-card-body {
    padding: 0px !important;
    min-height: 200px;
  }
  .testCard .ant-card-head {
    min-height: auto !important;
    padding: 0px 10px !important;
  }
  .flashcard-content div p {
    font-size: 15px !important;
    color: #282828;
    line-height: 21px;
    text-align: left;
    // display: inline-block;
  }
  .popupnoti{
    position: fixed;
    z-index: 1001;
    width: 512px;
    left: 34% !important;
    top: 64px;
    padding: 5px;
    border-radius: 6px;
  }
  .notifimm{
    background-color: #f7f7f7;
    box-shadow: 0px 0px 8px #bfbfbf !important;
    padding: 5px;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  .notif-survey i{
    color: black !important;
    margin-right: 5px;
    font-size: 23px;
    line-height: 17px;
  }
  .notif-survey{
    width: 100%;
    padding-bottom: 1px;
    color: #2b58ae;
    font-size: 17px;
   padding: 2px 12px 4px 12px !important;
    line-height: 24px;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    background-color: #f7f7f7;
  }
  .survey-description{
    font-size: 16px !important;
    font-weight: 600;
    color: #595959;
    line-height: 20px;
    padding: 8px 12px 7px 12px !important;
    background-color: #FFF;
      border-radius: 6px;
  }
  .ant-notification-notice-message{
    border-bottom: 1px solid #ececec;
    width: 100%;
    padding-bottom: 1px;
    margin-bottom: 4px !important;
    color: #2b58ae;
  }
  .ant-notification-notice-description{
    font-size: 15px !important;
    font-weight: 600;
  }
  .ant-notification-notice{
    padding: 3px 12px 7px 12px !important;
    margin-bottom: 6px !important;
    cursor: pointer;
    box-shadow: 0px 0px 6px #bfbfbf !important;
  }
  .tox-form__group .tox-form__controls-h-stack{
    display: block !important;
    width: 100% !important;
}
.tox-form__group .tox-form__controls-h-stack .tox-form__group:first-child{
    float: right;
    width: 150px;
    position: relative;
    right: 50px;
    } 
    .tox-form__group .tox-form__controls-h-stack .tox-form__group:nth-child(2) {
        float: left;
        width: 150px;
    } 
    .tox-form__group .tox-form__controls-h-stack .tox-form__group:last-child {
        float: left;
        width: 20px;
        position: relative;
        left: 174px;
    } 
  @media screen and (max-width:1130px) {
    .site-card-wrapper .row .col{
      padding: 0.25rem !important;
    }
    .table.table.studmyt.table-bordered.w-100 tr th:first-child{
      width: auto !important;
    }
  }
  
  @media screen and (max-width:1000px) {
    .number-card-v1 .card-info span {     height: auto !important;}
  }
  @media screen and (max-width:768px) {
    .number-card-v1 .card-info {    margin-top: -20px;}
    .number-card-v1 .card-top, .number-card-v1 .card-bottom {    padding: 20px 0px 38px 0px !important;   }
    .card-top{ height: 0.5rem !important;}

  }
  @media screen and (max-width:656px) {
    .name {
      padding: 5px 3px !important;
      font-size: 9px !important;

  }
 
  }
  @media screen and (max-width:478px) {
    .number-card-v1 .card-top, .number-card-v1 .card-bottom { font-size: 15px !important;}
    .number-card-v1 .card-info { 
      top: 76% !important;
  }
  .name {
    word-break: normal;
    padding: 3px 7px !important;
    font-size: 9px !important;
    line-height: 11px !important;
}
  .number-card-v1 {     background-color: #fafbfc !important; border: none !important;}
  }
  .ant-select-dropdown-menu-item-selected {color: green !important;}
  .ant-select-selected-icon{
    right: auto !important;
    margin-left: 5px !important;
  }