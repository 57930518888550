.app-header {
  padding: 0;
  border: 0; // no border, otherwise other elements' height can only be ($l_header_height -1)

  &.ant-layout-header {
    height: $l_header_height;
    line-height: $l_header_height;
    box-shadow: 0px 3px 5px #dfdfdf94;
    z-index: 555;
  }

  .app-header-inner {
    height: $l_header_height;
  }
}
.newtitle2023{
  color: #131D40 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  padding: 10px 0px 2px 0px;
}
.ant-popover-inner{
  box-shadow: 0 2px 8px #0000006b !important;
}
.slozered .ant-tabs-bar{
  margin-bottom: 0px !important;
  }
  .iconstyle23 button {
    height: 25px !important;
    background-color: #464a58  !important;
    border: 1px solid #464a58  !important;
    padding: 0 5px !important;
}
.changetabcss .ant-tabs-bar .ant-tabs-nav-container{
  background-color: #CFE0FF;
}
.sibledownb .ant-modal-content .ant-modal-body{
  padding: 0px !important;
}
.pdfmenusd .ant-menu-submenu .ant-menu-submenu-title a{
  color: #4c4c4c !important;
  font-size: 14px;
  line-height: 17px;
  padding: 0px;
}
.pdfmenusd-sub{
  height: auto !important;
  line-height: normal !important;
}
.pdfmenusd-sub a{
  font-size: 14px;
  line-height: 25px;
  height: 25px;
}
.pdfmenusd{
  border-right:none !important;
}
.pdfmenusd .ant-menu-submenu .ant-menu-submenu-title {
  height: 25px!important;
  line-height: 25px;
}
.pdfmenusd .ant-menu-submenu .ant-menu-submenu-title a .anticon-right{
  position: relative;
  left: 15px;
}
.alterbgclore .row:nth-child(odd){
 
  background: #fff;
  padding: 10px 0px !important;
  border-bottom: 2px solid #525252;
}
.alterbgclore{
  padding-bottom: 10px;
}
.alterbgclore .row:nth-child(even){
  background: #e5e5e5;
  padding: 10px 0px !important;
  border-bottom: 2px solid #525252;
}
.bil-inactive{
  padding: 9px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  border-bottom: 1px solid #e9e9e9;
  line-height: 21px;
}
.bli-active{
  padding: 9px 10px;
  font-size: 14px;
  font-weight: 800;
  background-color: #FFF !important;
  color: #4a4a4a;
  border-bottom: 1px solid #e9e9e9;
  line-height: 21px;
}
.changetabcss .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav-animated .ant-tabs-tab-active{
  background-color:#2a58ae;
}
.changetabcss .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav-animated .ant-tabs-tab-active b{
  color: #FFF !important;
}
.changetabcss .ant-tabs-bar{
  margin: 0px !important;
}
.righstab{
  background-color: #FAFAFA;
  border: 1px solid #E4E4E4;
  height: 100vh;
}
.studencolor{
  color: #26D723;
}
.bgcolorbds p{
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.bgcolordsw{
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.bgcolorbds{
  background-color: #FFF;
  padding: 6px;
  border-radius: 5px;
}
.rencolor{
  color: #347AFC;
}
.minseborder{
  background-color: #FFF;
    padding: 5px;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
}
.brdstyleline{
  border-bottom: 1px solid #f5f5f5;
}
.actionends{
  margin-bottom: 10px;
}
.tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:nth-child(1){
  width: 50px;
  max-width: 50px;
  min-width:50px;
  }
  .tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:nth-child(1){
    width: 50px;
    max-width: 50px;
    min-width:50px;
    }
.tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:nth-child(2){
  width: 110px;
  max-width: 110px;
  min-width:110px;
  }
  .tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:nth-child(2){
    width: 110px;
    max-width: 110px;
    min-width:110px;
    }
    .mutllotable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:nth-child(1){
      width: 110px;
      max-width: 110px;
      min-width:110px;
      }
      .mutllotable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:nth-child(1){
        width: 130px;
        max-width: 130px;
        min-width:130px;
        }
        .mutllotable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:nth-child(3){
          width: 130px;
          max-width: 130px;
          min-width:130px;
          }
          .mutllotable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:nth-child(3){
            width: 110px;
            max-width: 110px;
            min-width:110px;
            }
  .tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:last-child{
    text-align: right;
    width: 130px;
    max-width: 130px;
    min-width:130px;
    }
    .tablelmid150 .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:last-child{
      text-align: right;
      width: 130px;
      max-width: 130px;
      min-width:130px;
      }
.actionends .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:last-child{
  text-align: right;
  width: 180px;
  }
  .widthdrag40{
    width: 40.5% !important;
  }
  .subheadtitle{
    font-size: 15px;
    font-weight: 600;
    color: #2a58ae;
    line-height: 21px;
  }
  .springstep .ant-steps-item-container .ant-steps-item-icon{
    width: 22px !important;
    height: 22px !important;
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .posrescsb{
    position: relative;
    bottom: 15px;
  }
.pospopred{
  width: 700px !important;
}
.feeschats{
  position: relative;
    width: 90px;
    background-color: #FFF;
    border: 1px solid #ffe7b1;
    text-align: center;
    color: #FF6600;
    border-radius: 36px;
    bottom: 19px;
    font-size: 15px;
    padding: 5px 3px;
}
.pospopred .ant-modal-content .ant-modal-body{
  padding: 12px !important;
}
 .ant-modal-wrap:has(.onlyforcrmodule) {
z-index: 1001 !important;
 }
.videoPreviewIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  .play_icon{
    // scale: 1.05;
    font-size: 30px;
    cursor: pointer;
    padding:0 10px
  }
}
.chatboxss{
  width: 84%;
  font-size: 13px;
  color: #000;
  line-height: 18px;
  font-weight: 600;
  margin-top: 5px;
  border: 1px solid #ffe7b1;
  margin-bottom: 12px;
  border-radius: 5px;
  padding: 4px 8px 0px 8px;
  box-shadow: 5px 4px 8px #ffecc0;
}
  .springstep .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title{
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .slozered .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab{
    padding: 5px 14px !important;
  }
.posbgecolor video{
  width: 556px !important;
  height: 314px;
}
.posbgecolor iframe{
width: 556px !important;
height: 314px;
}
.main{
  background-color: #ffffff ;
}
.bglesson{
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  margin: 20px 0px !important;
}
.lessonnumber{
   background-color: #FFF;
    box-shadow: 0px 0px 5px #e5e5e5;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
.defending{
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
.defendingtext{
  width: 100%;
}
.creat{
  color:#2A58AE;
  font-weight: 500;
}
.topicname{
  padding-right: 0px ;
}
.ant-table-pagination.ant-pagination{
  margin: 8px 0px !important;
}
.brdradpad .ant-card-body{
  padding: 3px !important;
}
.app-header-inner {
  display: flex;
  justify-content: space-between;
  transition: $l_transition_antd;

  .logo-img {
    float: left; // fix its position affect other list items
    margin: 18px 15px 0; // ($l_header_height - height) / 2 = (60 - 24)/2
  }
.ant-btn-secondary{
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-secondary:hover{
  color: #fff !important;
  background-color: #464a58 !important;
  border-color: #464a58 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
  .ant-divider-vertical {
    background: rgba(222,222,222, .5);
    top: -2.5px;
  }

  .header-right {
    padding-right: 5px;
  }
.logo-mobile{
  height: 60px;
  padding: 0 0 0 3px;
  line-height: 50px;
}
div{
  font-size: 14px;
  font-weight: 500;
}
p{
  margin-bottom: 5px !important;
}

  .header-icon {
    display: inline-block;
    height: $l_header_height;
    line-height: $l_header_height;
    padding: 0 14px;
    @media only screen and (min-width: $l_screen_md_min) {
      padding: 0 18px;
    }
  }

  .ant-menu-horizontal {
    line-height: ($l_header_height - 2); // 2px border
    border-color: transparent;
  }

  a.list-inline-item {
    &:hover {
      background-color: rgba(0,0,0,.05);
    }
  }
  .list-inline-item {
    padding: 0 .875rem;
    margin: 0;
    transition: all .3s;

    .ant-badge {
      .ant-badge-count {
        font-size: 10px;
        line-height: 20px;
        font-weight: $font-weight-light;
        padding: 0 4px;
      }
    }

    .anticon {
      display: inline-block;
    }
    // apply to .list-inline-item icon only
    .list-icon {
      font-size: 1rem;
      line-height: $l_header_height;;
      padding: 0 4px;
    }
    .list-icon-v2 { // with no line-height, for alignment issue
      font-size: 1rem;
      padding: 0 4px;
    }
    .list-notification-icon {
      font-size: 1rem;
      padding: 4px;
    }

    .avatar-text {
      margin-left: .5rem;
    }
  }
}

.app-header-dropdown {
  .ant-dropdown-menu-item {
    i {
      margin-right: .5rem;
    }
  }
}

// 
.app-header-popover {
  max-width: 300px;
  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    max-width: none;
  }

  .ant-popover-inner-content {
    padding: 8px;
  }
}
.app-header-notifications {

  .ant-tabs-nav .ant-tabs-tab {
    padding: 1rem;
  }

  .ant-tabs-bar {
    margin-bottom: .25rem;
  }

  .ant-list-item {
    padding: .75rem 1.5rem;
    transition: background-color .2s;
    &:hover {
      background-color: rgba($info, .08);
      cursor: pointer;
    }
  }

  .ant-list-footer {
    padding: 0;

    a {
      display: block;
      padding: .75rem;
      text-align: center;
      color: $body-color2;
      transition: all .2s;

      &:hover {
        color: $body-color;
        background-color: rgba($gray-900, .03);
      }
    }
  }
}


.search-box {
  max-width: 180px;
  height: $l_header_height;
  line-height: 24px;

  &.list-inline-item {
    padding: 10px 0;
  }

  // based on Bootstarp .input-group
  .search-box-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // align-items: stretch;
  }

  input {
    position: relative;
    flex: 1 1 auto;
    padding: 7px 10px 5px;
    width: 1%;
    margin-bottom: 0;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
  }

  .search-box-icon {
    // display: flex;
    // align-items: center;
    padding: 8px 0 4px 10px;

    .anticon {
      padding: 0;
    }
  }

  // 
  .input-bar { 
    position: absolute; 
    display: block; 
    width: 90%;
    bottom: 0;
    left: 5%;
  }
  .input-bar:before,
  .input-bar:after {
    position: absolute;
    content: '';
    height: 1px; 
    width: 0;
    bottom: 1px; 
    background: $body-color; 
    transition: all .25s;
  }
  .input-bar:before {
    left: 50%;
  }
  .input-bar:after {
    right: 50%; 
  }  
  input {
    &:focus ~ .input-bar:before,
    &:focus ~ .input-bar:after {
      width: 50%;
    }                    
  }          
}
// Hack for vetical centering when .search-box is place on the right
.seach-box-right {
  .search-box-icon {
    position: relative;
    top: 2px;
  }
  input {
    padding: 9px 10px 3px;
  }
}
.ant-layout main .ui-animate{
  min-height: 500px;
  padding-bottom: 64px;
}
.coursetitle {
  color: #fff !important;
  font-weight: 600;
  display: contents;
  font-size: 15px;
  white-space: inherit !important;
  width: 95%;
  overflow: hidden;
}
.pdfHeadline{
  display: block;
  color: #2a58ae;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 6px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.minheigh232{
  min-height: 386px;
}
.emailidfor{
  font-size: 11px;
  font-weight: 500;
  color: #343434;
  line-height: 18px;
  padding: 0px 0px;
}
.contebfd{
  font-size: 15px;
  color: #3a3a3a;
  font-weight: 600;
}
.numberview{
  display: inline-block;
  padding: 3px 5px;
  text-transform: uppercase;
  line-height: 14px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size:11px;
  color: #606060;
  margin: 1px;
  border-radius: 36px;
}
.titHeadPdFr{
  font-size: 21px;
  font-weight: bold;
  color: #3a3a3a;
  line-height: 30px;
}
.learning23{
  border-bottom: 1px solid #dbdbdb;
  color: #787878;
  font-weight: 600;
}
.schodpdf{
  color: #2a58ae;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.scholdhead{
  font-size: 16px;
  font-weight: bold;
  color: #3a3a3a;
  line-height: 20px;
}
.textdots {
  color: #fff !important;
  font-weight: 600;
  font-size: 15px;
  text-overflow: ellipsis;
  line-height: 23px;
  overflow: hidden;
  white-space: nowrap;
  width: 93% !important;
  display: inline-block !important;
}
.bg-info img{
  margin-top: 0px;
}
section.sidenav-header.bg-info a {
  display: none;
}

.app-sidenav.ant-layout-sider-collapsed .sidenav-header{
  overflow: hidden;
  padding-left: 17.5px;
 
}
.app-sidenav.ant-layout-sider-collapsed .sidenav-header.bg-info::before {
  content: "";
  width: 4px;
  height: 59px;
  background: #2a58ae;
  position: absolute;
  right: 0px;
} 
.ant-btn-primary, .ant-switch-checked, .ant-checkbox-checked .ant-checkbox-inner,.ant-steps-item-process .ant-steps-item-icon{
  color: #fff;
  background-color: #2a58ae;
  border-color: #2a58ae;
  border-radius: 36px !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover, .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right: none !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon, .ant-btn-link, .ant-pagination-item-active a, .ant-menu-item-selected > a, .ant-menu-item > a:hover, .ant-menu-item-selected > a:hover .ant-menu-item-selected > a:hover{ 
  color:  #2a58ae !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: #dae7fe;
}
// .schedule-not-yet:hover td{
//   background-color:#ffff5c !important;
//   }
//   .schedule-in-btw:hover td{
//       background-color:#6aff6a !important;
//   }
//   .finish-reddish:hover td{
//       background-color:#ff8282 !important;
//   }
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
//   vertical-align: top !important;
// }
.ant-steps-item-finish .ant-steps-item-icon, .ant-input:hover, .ant-pagination-item-active, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #2a58ae !important;
}
.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{
  border-color: none !important;
  box-shadow:none !important;
}
.ant-input-number-input:focus{
  border-color:none !important;
  box-shadow:none !important;
}
.ant-input:focus{
  box-shadow: none !important;
  border-color: #2a58ae !important;
}
html {
  --antd-wave-shadow-color: none !important;
}
.minheigh200{
  min-height: 550px;
}
.ant-input-number:hover{
  border-color: #2a58ae !important;
  box-shadow:none !important;
}
.ant-select-open .ant-select-selection{
  border-color: #2a58ae !important;
}
@-moz-document url-prefix() {
  .dottedlinks{
      margin-right:18px !important;
  }
}
.dottedlinks{
  width: 12px;
  height: 20px;
  background-color: #000;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-right: 4px;
  display: block;
  margin-left: 10px;
  cursor: pointer;
}
.ant-tooltip{
  max-width: 286px !important;
}
.dottedlinks:hover{
background-color: #2a58ae;
}
.posdotleft{
  position: fixed;
    left: 0px;
    top: 99px;
    z-index: 55;
    max-height: 480px;
    overflow-x: auto;
}
.posdotleft::-webkit-scrollbar {
  width: 5px !important;
}
// .dottedlinks:has(.hoverdooted:hover) {
//   display: block;
//   background-color: #000;
// }

// .ant-select-selection, .ant-input-number-input{
//   border: 1px solid #d9d9d9;
// }
.ant-select-selection:hover{
  border-color: #2a58ae !important;
}
.ant-checkbox-checked::after{
  border: 1px solid #2a58ae;
}
.ant-btn-primary:hover, .ant-btn-primary:focus{
  background-color: #5f81c2  !important;
 border-color: #5f81c2  !important;
}
.w-48{
  border: 1px solid #ededed;
  margin: 5px;
  width: 48% !important;
}


.sizeantpop{
  padding: 0px !important;
  margin-bottom: 10px !important;
}
.w-48 .ant-card-body{
  padding: 0px !important;
}
.sizeantpop .ant-card-body{
  padding: 0px 8px !important;
}
.ant-btn-primary:hover, .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
  background-color: #5f81c2  !important;
  border-color: #5f81c2  !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background: #2e5cb4 !important;
}
.tags-input{
  border-radius: 6px;
}
.form-v1-container .lead {
  margin-bottom: 0px !important;
  font-size: 14px;
  margin-top: 6px;
}
.form-v1-container h2 + .form-v1, .form-v1-container .lead + .form-v1 {
  margin-top: 20px !important;
}
.logimg{
  height: auto !important;
  width: 160px !important;
  z-index: 574;
  position: relative;
}
.logosec{
  z-index: 574;
  position: relative;
  width: 164px;
  top: 5px;
}
.logforpng{
  width: 232px !important;
  margin: 0px auto;
  display: flex;
}
.app-header-inner {
  background-color: #FFF !important;
}
.sidenav-header {
  background-color: #FFF !important;
  position: fixed;
  z-index: 600;
}
.app-sidenav .sidenav-header {
  padding: 0 0 0 0px !important;
}
.posficesidenav{
  position: fixed;
  width: 70%;
  top: 10px;
  right: 16%;
  z-index: 599;
}
.bgcolorcli{
  background-color: #464A58 !important;
}
.bgcolorcli ul{
  color: #FFF !important;
  background: #FFF !important;
  display: -webkit-flex;
  display: flex;
}
.bgcolorcli ul li{
  padding:0px !important;
  height: auto !important;
  width: 20% !important;
}
.appsidenew{
  -webkit-flex:100% !important;
   flex: 100% !important;
   max-width: initial !important;
   min-width: auto!important;
   width: 100% !important;
}
.bordernone{
  border: none !important;
}
.colordrakblack {
  color: #464A58 !important;
}
// .ant-layout main .container-fluid{
//   min-height: 750px;
// }
.roundcir {
  width: 36px !important;
  height: 36px !important;
  background-color: #464A58 !important;
  border-radius: 30px !important;
  color: #FFF !important;
  font-size: 16px !important;
  padding: 8px;
}
.small {
  color: #FFF;
}

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: #696969 !important;
// }
.ant-menu-item-selected {
  background-color: #f0f3ff !important;
  border-radius: 2px 2px 0px 0px;
  border-bottom: 2px solid #2a58ae;
}
.ant-menu-item-selected .menutext .whitetext {
  font-weight: 700;
  color: #000 !important;
}
.menuitem{
  margin: 0px !important;
}
.ontologyBrief div p img{
  float: initial !important;
}
.ontologyBrief img{
float: initial !important;
}
.mobileview {
  border-bottom: none !important;
}
.posficesidenav .bgcolorcli {
  height: 40px;
}
.menutext{
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: center !important;
  text-transform: uppercase !important;
  padding: 0px 2px;
  vertical-align: inherit;
}
.whitetext{
  color: #464a58  !important;
  white-space: break-spaces !important;
  padding: 7px 0px;
}
.imgicr{
  display: none;
  width:25px;
  margin: 0px auto 0px auto;
}
.imgicr img{
  width: 100%;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2a58ae  !important;
  border-color: #2a58ae  !important;
  border-radius: 36px !important;
}
.ant-btn-primary{
  background-color: #2a58ae  !important;
  border-color: #2a58ae  !important;
  border-radius:36px !important;
}
.borderrd{
  border-radius: 36px !important;
}
.borderrd:hover{
  border: 1px solid #555 !important;
  color: #FFF !important;
}
.main-title, .article-title{
  color: #131D40 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  padding: 0px 0px 5px 0px;
}
.bgfixed{
  position: fixed;
  z-index: 555555;
}
.setitablewidth .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th{
  background-color: #2a58ae !important;
}
.bgliera{
  background-image: linear-gradient(to bottom, #65656500, #f3f3f3b8);
}
.textforbgcle-bs {
  width: 100%;
  border-radius: 0.375rem 0.375rem 0px 0px;
  
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0px 2px 5px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  color: #2a2a2a;
}
.menutoggle{
  display: none !important;
}
.blueslne{
  color: #4b708a!important;
  font-size: 17px;
  line-height: 20px;
  position: relative;
  top: 5px;
}
.modpopwidth .ant-modal-content .ant-modal-body{
  padding: 12px !important;
}
@media screen and (max-width: 1256px){
  .logo-mobile{
    display: block !important;
  }
  .posficesidenav{
    display: none;
  }
  // .atdbox .ant-avatar-circle{
  //   display: none;
  // }
  .menudrop {
    background-color: #464a58;
    border-radius: 0px;
    padding:0px  !important;
  }
  .ant-dropdown-menu-item {
    color: #ffffff;
    font-weight: lighter;
    font-size: 14px;
}
.ant-dropdown-menu-item > a {
  color: #000;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 0px);
  padding: 0px 8px !important;
}
.menudrop .d-block{
  padding: 10px !important;
  display: block !important;
}

.menuitem {
  border-bottom: none;
}
.whitetext {
  color: #ffffff !important;
}

.ant-menu-item-selected {
  background-color: #456db8 !important;
}
.ant-menu-item-selected .menutext .whitetext{
  font-weight: 600;
  color: #fff !important;
}
.ant-menu-item-selected{
  line-height: initial !important;
}
.ant-menu-item menuitem{
  line-height: initial !important;
}
.imgicr {
  display: inline-block;
  width: 17px;
  margin: 0px 6px 0px 0px;
}
.menutext {
  font-size: 12px !important;
  font-weight: lighter !important;
  text-align: left !important;
  text-transform: none !important;
  text-transform: initial !important;
  line-height: initial !important;
  padding: 2px 0px !important;
}
.menuitem {
  padding: 0px !important;
}
.ant-dropdown-menu-item{
  padding: 0px;
}
.ant-menu-inline {
  border-right: none;
  padding: 0px;
}
.mobileview {
  display: block;
  padding: 6px 10px !important;
}
.mobileview a{
  color: #fff !important;
}
.ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
  display: none !important;
}
  .iconbell{
    padding: 0px !important;
  }
  .list-inline-item {
    vertical-align: top;
}
  .roundcir{
    display: none !important;
  }
  .menutoggle{ display: block !important;
  color: #464A58 !important;
  font-size: 29px !important;
  padding: 16px 0px;
  }
  .nav-info{
    padding-left: 3px;
  }
  .ant-popover-inner-content{
    display: flex;
  }
}
@media screen and (max-width: 700px){
.pospopred{
  width: 96% !important;
}
}

.backinfo{
  font-size: 14px !important;
  font-weight: 600;
  line-height: 19px;
  padding-top: 5px;
  color: #2A58AE !important;
}
.backinfo  a{
  font-size: 14px;
  padding-left:6px;
  line-height: 19px;
  font-weight: 600;
  text-transform: initial !important;
  color: #2A58AE !important;
}
.backinfo  a:hover{
text-decoration: underline;
}

.matchperdisk{
  background: rgb(200,234,255);
  padding: 8px !important;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
    margin:0px auto 5px auto !important;
background: linear-gradient(169deg, rgba(200,234,255,1) 0%, rgba(255,235,245,1) 35%, rgba(230,255,240,1) 100%);
}
.matchquetsmark{
  padding: 3px 7px !important;
  margin-bottom: 2px;
  position: relative;
  border: 1px solid #dddddd;
  border-right: 2px solid #2a58ae;
  background-color: #fbfbfb;
  /* right: 22px; */
  min-height: 35px;
  font-size: 14px !important;
  /* height: auto; */
  font-size: 14px !important;
  width: 100%;
  line-height: 20px;
}
.heaserms {
  color: #b3b3b3 !important;
  font-size: 13px !important;
  font-weight: normal;
  position: relative;
}
.matchquetsmarkanswerp p{
  padding: 3px 7px !important;
  margin-bottom: 2px;
  position: relative;
  border: 1px solid #dddddd;
  border-left: 5px solid #2a58ae;
  background-color: #fbfbfb;
  /* right: 22px; */
  min-height: 35px;
  font-size: 14px !important;
  /* height: auto; */
  font-size: 14px !important;
  width: 100%;
  line-height: 20px;
}