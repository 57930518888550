

// make sure it overrides material2 styles
a,
button {
  &.btn-w-xs { min-width: 80px; }
  &.btn-w-sm { min-width: 100px; }
  &.btn-w-md { min-width: 135px; }
  &.btn-w-lg { min-width: 160px; }

  &.btn-round { border-radius: 2em; }
}

.btn-cta.ant-btn, // antd
.btn-cta {
  height: auto;
  padding: .6em 3.5em;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: 2;
  font-size: $font-size-sm;
  border-radius: 36px !important;
}
.ant-btn:active, .ant-btn.active{
  border-color: white !important;
}
.butrad{
  border-radius: 36px !important;
  background-color: #464a58 !important;
  border: 1px solid #464a58 !important;
  color: #FFF !important;
}
.minwid{
  min-width: auto !important;
}
.ant-btn {
  border-radius: 36px !important;
  background-color: #464a58;
  color: #FFF;
}
.ant-btn:hover{
  background-color: #555;
  border: 1px solid #555;
  color: #FFF !important;
}
.ant-btn-secondary{
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-secondary:hover{
  color: #fff !important;
  background-color: #464a58 !important;
  border-color: #464a58 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{
  box-shadow: none !important;
}
.btn-secondary:focus, .btn-secondary.focus{
  box-shadow: none !important;
}
.butrad:hover{
  color: #000 !important;
  background-color: #bcbcbc !important;
  border: 1px solid #bcbcbc !important;
}
.ant-btn-info, .ant-btn-secondary{
  border-radius: 36px !important;
}
.ant-modal-footer{
  border-top: none !important;
}

.buttoclose{
  background-color: #e7e7e7 !important;
  padding: 5px;
  border: 1px solid #e7e7e7 !important;
  }
  .buttoclose2{
    // background-color: #fff !important;
    padding: 5px;
    border: 1px solid #fff !important;
  }
// 
a.icon-btn-v2,
.icon-btn-v2 {
  color: $white;
  height: auto;
  padding: .5em 2.5em;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
  }

  i {
    margin-right: .5rem; 
  }

  .btn-text {
    letter-spacing: .5px;
    text-transform: uppercase;
    font-size: $font-size-sm;
  }
}
.info{
  background-color: #2A58AE !important;
  color:white
}
.info:hover{
  background-color: #2A58AE;
  color:white
}
::-webkit-scrollbar-thumb {
  background: #dae7fe;
  border-radius: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d4d4d4;
  border-radius: 0px;
}
::-webkit-scrollbar {
  width: 12px;
  height: 7px;
}