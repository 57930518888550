
.activeuser{
  width: 124px;
  display: flex;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td{
  padding: 0px 8px !important;
  vertical-align: top;
}
.datepickerlo{
  max-width: 120px;
}
.goalnum{
  max-width: 100%;
}
.resize-none{
  resize: none;
}
.editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-child(2)  {
  width: 140px;
   }
   .rowmax {
    display: flex;
    flex-wrap: wrap;

}
.clpd-12{
  border: 1px solid #adadad;
  /* margin: 5px; */
  /* width: 98%; */
  flex: 0 0 97%;
  max-width: 97%;
  margin: 0px auto 5px auto;
  background-color: #c4caf7;
  padding: 5px 10px;
}
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(4){
    min-width: 130px;
   }
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th .ant-table-header-column {
    position: relative;
    top: 2px;
    font-size: 14px;
    }
  //  .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(5){
  //   max-width: 240px;
  //  }
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(6){
    max-width: 150px;
   }
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(7){
    max-width: 160px;
   }
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(8){
    max-width: 160px;
   }
 .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-child(2) {
   width: 140px;
     }
.editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-child(3)  {
  width: 62px;
   }
 .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-child(3) {
   width: 62px;
     }
     .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-child(5)  {
      width: 62px;
       }
     .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-child(5) {
       width: 62px;
         }
         .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
          width: 110px;
           }
         .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
           width: 110px;
             }
             .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:first-child  {
              width:40px;
               }
             .editlearning .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
               width:40px;
                 }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-child(4)  {
 width: 62px;
  }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-child(4) {
  width: 62px;
    }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-child(2)  {
  width: 62px;
  }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-child(2) {
  width: 62px;
    }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:first-child  {
  width: 138px;
  }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
  width: 138px;
    }
    .learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
      padding: 6px !important;
        }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
  width: 75px;
  }
.learningtable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
  width: 75px;
    }
.ant-table-wrapper .ant-spin-nested-loading .ant-table .ant-table-content .ant-table-body table .ant-table-tbody .ant-table-row:nth-child(even){background-color: #f2f2f2;}
.ant-table-wrapper .ant-spin-nested-loading .ant-table .ant-table-content .ant-table-body table .ant-table-tbody .ant-table-row:nth-child(odd){background-color: #fff;}
.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
  text-align: center;
}
.studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
  width: 78%;
  display: flex;
    }
   .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
    width: 96px;
    }
    .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(2) {
      min-width: 130px;
      padding: 0px;
        }
       .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-last-child(2)   {
        padding: 0px;
        min-width: 130px;
        }
.quizetable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
width: 96px;
text-align: right;
  }
 .quizetable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
  width: 96px;
  text-align: right;
  }
.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
  width: 96px;
  text-align: right;
    }
   .semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
    width: 96px;
    text-align: right;
    }
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
max-width: 390px;
}
.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(2) {
  max-width: 110px;
  }
 .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-last-child(2)  {
  max-width: 110px;
  }
  .studentsearch .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:last-child {
    width: 80px;
    text-align: right;
    }
   .studentsearch .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:last-child  {
    width: 80px;
    text-align: right;
    }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    font-size: 15px;
    padding: 2px 10px;
    vertical-align: top;
    width: auto;
  }
@media only screen and (max-width: 1250px) {
  .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 12px !important;
    padding: 8px 4px !important;
  }
  
  .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
  
  
  .setupgroup .ant-form-horizontal .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 12px !important;
    padding: 8px 4px !important;
}
.setupgroup .ant-form-horizontal .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 4px !important;
  font-size: 12px;
}
.schooltable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 12px !important;
  padding: 8px 4px !important;
}
.schooltable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 4px !important;
  font-size: 12px;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 12px !important;
  padding: 8px 4px !important;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 4px !important;
  font-size: 12px;
}
  .stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 12px !important;
    padding: 8px 4px !important;
}

.stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    padding: 8px 4px !important;
    font-size: 12px;
}
.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 12px !important;
  padding: 8px 4px !important;
}

.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 4px !important;
  font-size: 12px;
}
.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 12px !important;
  padding: 8px 4px !important;
}

.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 4px !important;
  font-size: 12px;
}
.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td div div .ant-select-enabled{
  font-size: 12px;
  width: 150px !important;
}


}
@media only screen and (max-width: 970px){
  .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 11px !important;
    padding: 8px 6px !important;
  }
  
  .studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    padding: 8px 6px !important;
    font-size: 11px;
  }
  .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 11px !important;
    padding: 8px 2px !important;
  }
  
  .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    font-size: 11px !important;
    padding: 8px 2px !important;
  }
  .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td div div .ant-select-enabled{
    font-size: 11px;
    width: 120px !important;
  }
  .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(2) {
    max-width: 120px;
    }
   .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-last-child(2)  {
    max-width: 120px;
    }
 
}
@media only screen and (max-width: 920px) {
  .stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
    font-size: 11px !important;
    padding: 8px 6px !important;
}
.studentact i{
  vertical-align: top;
  position: relative;
  bottom: 8px;
  left: 4px;
}
.stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    padding: 8px 6px !important;
    font-size: 11px;
}
.setupgroup .ant-form-horizontal .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 11px !important;
  padding: 8px 6px !important;
}
.setupgroup .ant-form-horizontal .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 6px !important;
    font-size: 11px;
}
.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 11px !important;
  padding: 8px 6px !important;
}

.semister .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 6px !important;
  font-size: 11px;
}
.studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 2px !important;
  font-size: 10px;
  line-height: 13px;
}
.studentstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  padding: 8px 2px !important;
  font-size: 10px;
  line-height: 13px;
}
.activeuser{
  width: 100% !important;
}
.activeuser .ant-select{
width: 87% !important;
display: inline-block;
float: left;
font-size: 11px;
}
.ant-select-dropdown-menu-item {
  padding: 5px 6px;
  line-height: 14px;
  font-size: 11px;
}
.editicons {
  position: relative;
  bottom: 8px;
  left: 4px;
}
}
@media only screen and (max-width: 830px){
.stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
    padding: 8px 2px !important;
    font-size: 11px;
}
.stafftable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 11px !important;
  padding: 8px 2px !important;
}


.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 10px !important;
  padding: 8px 2px !important;
}

.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  font-size: 10px !important;
  padding: 8px 2px !important;
}
.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td div div .ant-select-enabled{
  font-size: 10px;
  width: 100px !important;
}
.setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:nth-last-child(2) {
  max-width: 100px;
  }
 .setitable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th:nth-last-child(2)  {
  max-width: 100px;
  }
}

// flip scroll (Pure CSS), thanks to http://elvery.net/demo/responsive-tables/#flip-scroll
@media only screen and (max-width: 800px) {
  .table-flip-scroll {
    .cf:after { visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0; }
    * html .cf { zoom: 1; }
    *:first-child+html .cf { zoom: 1; }
    table { width: 100%; border-collapse: collapse; border-spacing: 0; }

    th,
    td { margin: 0; vertical-align: top; }
    th { text-align: left; }
    table { display: block; position: relative; width: 100%; }
    thead { display: block; float: left; }
    tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }
    thead tr { display: block; }
    .table > thead > tr > th:first-child { // add to Bootstrap style
      border-top: 1px solid #ddd;
    }
    th { display: block; text-align: right; }
    tbody tr { display: inline-block; vertical-align: top; }
    td { display: block; min-height: 1.25em; text-align: left; }


    /* sort out borders */

    th { border-bottom: 0; border-left: 0; }
    td { border-left: 0; border-right: 0; border-bottom: 0; }
    tbody tr { border-left: 1px solid #babcbf; }
    th:last-child,
    td:last-child { border-bottom: 1px solid #babcbf; }
  }
}

@media only screen and (max-width: 676px) {
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
  max-width: 250px;
}
.studequestiontablesntstb .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 11px !important;
  padding: 8px 6px !important;
}

.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 6px !important;
  font-size: 11px;
}
}

@media only screen and (max-width:585px){
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
    max-width: 184px;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  padding: 8px 2px !important;
  font-size: 11px;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
font-size: 11px !important;
padding: 8px 2px !important;
}
}
@media only screen and (max-width:438px){
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td:first-child {
  max-width: 113px;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-thead tr th {
  font-size: 10px !important;
}
.questiontables .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  font-size: 10px;
  line-height: 12px;
}
}