
// Modal
.custom-modal-v1 {
  width: 100% !important;
  max-width: 1000px;

  .ant-modal-close {
    right: .5rem;
    top: .5rem;
    border-radius: 50%;
    background-color: rgba(0,0,0,.3);
    color: $gray-200;
  }

  .ant-modal-close-x {
    width: 1.5rem;
   
    height: 1.5rem;
    line-height: 1.5rem;
 
    font-size: $font-size-sm;
    opacity: $text-inactive;
    transition: opacity .3s;
    &:hover {
      opacity: 1;
    }
  }

  .ant-modal-body {
    // padding: 0;
  }
}
.ant-modal-close-x{
  background-color: #464a58 !important;
  background-color: #464a58 !important;
  height: 34px !important;
  width: 34px !important;
  border-radius: 36px;
  line-height: 36px !important;
  margin: 5px;
}
.ant-modal-close-x i{
  color: #FFF;
}

.ant-switch{
  border-radius: 36px !important;
}
.ant-drawer-close{
  background-color: #464a58 !important;
  line-height: 28px !important;
  width: 34px !important;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 36px;
  height: 34px !important;
}
.ant-drawer-close i{
  color: #FFF;
}